import { Injectable } from '@angular/core';
import {HttpClient, HttpEvent} from "@angular/common/http";
import { map, retry, catchError } from 'rxjs/operators';
import {Observable, of} from "rxjs";
import {environment} from "../../environments/environment";
import {ApiEvent} from "./apievent.model";


@Injectable({
  providedIn: 'root'
})
export class ApiService {

  readonly ROOT_URL = environment.api_url;

  constructor(private http: HttpClient) { }

  validateJWT(jwt: string): Observable<any> {
    const data = {
      jwt: jwt,
    }

    return this.http.post<ApiEvent>(this.ROOT_URL + '/validate', data).pipe(
      retry(2),
      map((event: ApiEvent) => this.getEventMessage(event)),
      catchError(err => of([]))
    );
  }

  private getEventMessage(event: ApiEvent): ApiEvent {
    if (!event.error) {
      return event;
    }

    switch (event.code) {
      case 123:
        return {error: event.error, msg: "Derp"} as ApiEvent;
      case 5002:
        return {} as ApiEvent;
      case 5003:
        return {} as ApiEvent;
      default:
        return {} as ApiEvent;
    }
  }
}
